.not-implemented-page-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.not-implemented-page-grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 3.2rem;
  column-gap: 5.2rem;
  row-gap: 8rem;
  height: 100vh;
  align-items: center;
}

.not-implemented-page-grid-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.not-implemented-page-header{
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 3rem;
  text-align: center;
  text-transform: uppercase;
}

.not-implemented-page-header:first-of-type{
  margin-bottom: 0;
}

.not-implemented-page-grid-animation{
  display: flex;
  align-items: center;
  justify-content: center;
}

.not-implemented-page-lottie{
  width: 70%;
  height: 100%;
}

/* ----------- MEDIA QUERIES ----------- */
@media screen and (min-width: 801px) and (max-width: 1200px){
  .not-implemented-page-grid{
    column-gap: 0;
  }
}

@media screen and (max-width: 800px){
  .not-implemented-page-grid{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    height: auto;
    row-gap: 0;
    align-items: start;
  }

  .not-implemented-page-grid-animation{
    grid-row: 1;
  }

  .not-implemented-page-lottie{
    width: 20rem;
  }

  .not-implemented-page-grid-text{

    grid-row: 2;
  }

  .not-implemented-page-header{
    font-size: 1.5rem;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 500px){

  .not-implemented-page-grid{
    margin-bottom: 5rem;
  }
  .not-implemented-page-lottie{
    width: 15rem;
  }

  .not-implemented-page-header{
    font-size: 1.2rem;
  }

  .not-implemented-page-link{
    font-size: 1rem;
  }
}
