.faq-container {
  padding: 0 2rem;
  margin-bottom: 5rem;
}

.faq-header {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 3rem;
  text-align: center;
  text-transform: uppercase;
}

.faq-accordion-container {
  padding: 0 20rem;
}

.accordion-button {
  color: #001f3f;
  font-size: 1.2rem;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 2rem;
  border: 2px solid #666 !important;
  border-radius: 9px !important;
  transform: 0.1s ease-in-out !important;

}

.accordion-button:not(.collapsed) {
  border-bottom: none !important;
  border-radius: 9px 9px 0 0 !important;
}

.accordion-item {
  margin-bottom: 1rem;
  border: none;
}

.accordion-body {
  color: #333;
  font-size: 1rem;
  font-weight: 500;
  padding: 1rem 2rem;
  border-radius: 0 0 9px 9px;
  border: 2px solid #666;
  border-top: none;
}

.accordion-button:not(.collapsed) {
  background-color: white;
  color: #001f3f;
}

.accordion-button:active {
  background-color: white;
  color: #001f3f;
  box-shadow: none;
}

.accordion-button:focus {
  background-color: white;
  color: #001f3f;
  box-shadow: none;
}

/* ------------ MEDIA QUERIES ------------ */
@media screen and (max-width: 1300px) {
  .faq-accordion-container{
    padding: 0 10rem;
  }
}

@media screen and (max-width: 950px) {
  .faq-accordion-container{
    padding: 0 5rem;
  }
}

@media only screen and (max-width: 768px) {
  .faq-container {
    padding: 0 1rem;
  }

  .faq-accordion-container {
    padding: 0 1rem;
  }
}