.repair-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.repair-intro-text {
  font-size: 1.2rem;
  line-height: 1.5;
  width: 100%;
  text-align: center;
}

.repair-services-text {
  font-size: 1.2rem;
  line-height: 1.5;
  width: 100%;
  text-align: center;
}

.repair-cta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.repair-intro-text {
  font-size: 1.2rem;
  line-height: 1.5;
  width: 100%;
  text-align: center;
}

.repair-services-text {
  font-size: 1.2rem;
  line-height: 1.5;
  width: 100%;
  text-align: center;
}

.repair-services-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  margin: 2rem 0;
  list-style: none;
  padding-left: 0;
}

.repair-services-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  padding: 1rem;
  align-content: stretch;
}

.repair-services-list-item-text {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #212529;
  position: relative;
}

.repair-services-list-item:hover .repair-services-list-item-text{
  font-weight: 600;
  color: #E04733;
}

.repair-services-list-item-icon {
  width: auto;
  height: 4rem;
  object-fit: cover;
  object-position: center;
}

.repair-cta-container {
  display: flex;
  flex-direction: row;
  justify-content: center !important; /* Change this line */
  text-align: center !important;
  width: 100%;
  gap: 2rem;
}

.repair-cta-button {
  width: auto;
  margin-bottom: 0;
}

.repair-form-container {
  margin-top: 2rem;
  /* gap: 2rem; */
}

@media screen and (max-width: 884px) {
  .repair-cta-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    gap: 2rem;
  }

  .repair-cta-button {
    width: 20rem;
    margin: 0;
  }
}

@media screen and (max-width: 650px) {
  .repair-services-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 499px) {
  .repair-services-list {
    grid-template-columns: repeat(1, 1fr);
  }
  .repair-cta-button {
    width: 20rem;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 650px) {
  .repair-services-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 499px) {
  .repair-services-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
