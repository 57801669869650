.sos-car-tow-intro-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0rem;
  text-align: center;
  margin-bottom: 1rem;
  align-items: center;
}

.sos-car-tow-intro-img-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 3rem;
  margin: auto 0;
}

.sos-car-tow-intro-img {
  width: 20rem;
  height: 15rem;
  object-fit: cover;
  object-position: center bottom;
}

.sos-car-tow-intro-img-text {
  font-size: 0.8rem;
  line-height: 1.5;
  color: #212529;
  margin-top: 1rem;
}

.sos-car-tow-hiw-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* width: 70%; */
  margin: 0 auto;
}

/* ------ CTA ------ */

.sos-car-tow-CTA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* width: 70%; */
  margin: 3rem auto;
}

.sos-car-tow-CTA-title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  color: #212529;
}

.sos-car-tow-CTA-subtitle {
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.5;
  color: #212529;
  margin-bottom: 0;
}

/* ------ HOW IT WORKS ------ */
.sos-car-tow-hiw-container {
  margin-bottom: 3rem;
}
.sos-car-tow-hiw-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.sos-car-tow-hiw-text:hover {
  transform: scale(1.05);
}
.sos-car-tow-hiw-text:hover .sos-car-tow-hiw-step {
  color: #e04733;
}

.sos-car-tow-hiw-title {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  color: #001f3f;
}

.sos-car-tow-hiw-step {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.5;
  color: #001f3f;
}

.sos-car-tow-hiw-text-content {
  width: 70%;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #212529;
  margin: auto;
}

/* ----- INTRO ------ */
.sos-car-tow-intro-text {
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #212529;
  text-align: center;
}

/* --------- MEDIA QUERIES --------- */
@media only screen and (max-width: 768px) {
  .sos-car-tow-intro-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .sos-car-tow-intro-img-wrapper {
    padding-left: 0;
  }
}

@media only screen and (max-width: 500px) {
  .sos-car-tow-container {
    padding: 0 1rem;
  }
  .sos-car-tow-hiw-step {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 400px) {
  .sos-car-tow-intro-img {
    width: 15rem;
    height: 10rem;
  }
}
