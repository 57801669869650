/* --------------------- FOOTER ---------------------*/
/* ------------ FOOTER CONTAINER AND GRID ------------ */

.outer-div {
  width: 100%;
}
.footer-container {
  background-color: #001f3f;
  color: white;
  padding: 3rem 5rem 1rem 5rem;
  /* max-width: 120rem; */
  margin: 0 auto;
}
.footer-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
}

/* ------------ FOOTER - LOGO COLUMN ------------ */

.logo-col {
  list-style: none;
  justify-content: center;
  text-align: center;
}

.footer-logo-img{
  width: 15rem;
  height: 15rem;
  margin-bottom: 1rem;
}

.social-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin-bottom: 1rem;
}

.facebook-logo {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

/* ------------ FOOTER - ADDRESS COLUMN ------------ */ 

.footer-heading {
  font-size: 1.5rem;
  font-weight: 700;
  height: 3rem;
  margin-bottom: 2rem;
  text-align: center;
  align-items: center;
}

.address-col {
  text-align: center;
}

.footer-address-title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.footer-address-text {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  /* text-decoration: none;
  color: white; */
}

.contact-div a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}


/* ------------ FOOTER - CONTACT COLUMN ------------ */
.footer-contact-from-input {
  width: 100%;
  height: 2rem;
  border-radius: 4.5px;
  border: 1px solid #334c65;
  font-size: 0.8rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.footer-contact-form-textarea {
  width: 100%;
  height: 4rem;
  border-radius: 4.5px;
  border: 1px solid #334c65;
  font-size: 0.8rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.footer-contact-form-submit-btn {
  width: 100%;
  height: 2rem;
  border-radius: 4.5px;
  border: 1px solid #334c65;
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: #E04733;
  color: white;
  font-size: 0.8rem;
  font-weight: 700;
}

.footer-contact-form-submit-btn:hover {
  background-color: #E04733;
  color: #001f3f;
}

.footer-container .docs-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  color: white;
  font-size: 0.8rem;
}

/* .footer-container .docs-row .footer-link{
  align-items: center;

} */


/* ------------ FOOTER - DOCS ROW ------------ */

.footer-docs-links {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}

.footer-link {
  text-decoration: none;
  color: white;
  font-size: 0.8rem;
}

.footer-link:hover {
  color: #0074d9;
}

/* ------------ FOOTER - COPYRIGHT ROW ------------ */

.footer-container .copyright-row {
  width: 100%; /* Takes full width */
  text-align: center; /* Center the content horizontally if desired */
  padding: 1rem 0; /* Adjust padding as needed */
  color: white;
  font-size: 0.8rem;
  /* Any other styles you may want to apply */
  border-top: 1px solid #334c65;
}

.copyright-text {
  margin: 0;
}

/* --------------------- MEDIA QUERIES --------------------- */

@media screen and (max-width: 950px) {
  .footer-container {
    padding: 3rem 2rem 1rem 2rem;
  }
}

@media screen and (max-width: 800px) {
  .footer-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .logo-col {
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .footer-docs-links {
    width: 100%;
  }

  .footer-contact-form-submit-btn {
    width: 100%;
  }
}

@media screen and (max-width: 599px) {
  .footer-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .contact-col{
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .logo-col {
    grid-row: 3;
  }

  .footer-contact-form-submit-btn {
    width: 100%;
  }

  .footer-docs-links {
    width: 100%;
  }
}
