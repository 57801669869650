.sid-title{
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  color: #001F3F;
  text-align: center;
  margin-bottom: 2rem;
}

.sid-grid{
  display:grid;
  grid-template-rows: repeat(2, auto);
  grid-gap: 2rem;
}

.sid-image-grid{
  display:grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}

.sid-image-container{
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}

.sid-image-grid img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.sid-image-grid img:hover{
  transform: scale(1.1);
}


.sid-text-grid{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 2rem;
}

.sid-text{
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #212529;
  margin-bottom: 0;
}

.sid-button{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  margin: auto;
}

/* ------ MEDIA QUERIES ------ */
@media screen and (max-width: 600px){
  .sid-grid{
    grid-template-rows: repeat(1, auto);
  }
  .sid-image-grid{
    grid-template-columns: repeat(2, 1fr);
  }
}