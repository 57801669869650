.home-partners-container {
  /* background-color: #f0f0f0; */
  padding: 3rem 0;
}

.home-partners-title {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 3rem;
}


.home-partners-logo {
  height: 4rem;
  margin: 3rem ;
}