.banner-container {
  background-color: #e6f1fb;
  padding: 3rem 1.5rem;
  width: 100%;
  height: 100%;
  margin-top: 5.3rem;
  margin-bottom: 3rem;
  color: #001f3f;
}

.banner-text {
  font-size: 4rem;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .banner-container {
    padding: 2rem 1rem;
  }
  .banner-text {
    font-size: 2rem;
  }
}
