.prices-text-container,
.prices-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 3.2rem;
}

.prices-container {
  margin-bottom: 5rem;
}

.prices-text {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  text-align: center;
  line-height: 1.5;
}

.prices-table {
  padding: 0 3.2rem;
  column-gap: 5.2rem;
  row-gap: 8rem;
  margin-top: 3rem;
}

.prices-table thead tr th {
  background-color: #001f3f;
  color: white;
  font-weight: 600;
  margin: auto;
  padding: 1rem; /* Adjust as needed */
  height: 5rem;
  display: table-cell;
  vertical-align: middle; /* Add this */
  position: sticky;
  top: 0;
}

.prices-table thead tr th:first-of-type {
  border-radius: 4.5px 0 0 0;
}

.prices-table thead tr th:last-of-type {
  border-radius: 0 4.5px 0 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: #e6f1fb;
}

.table-first-column {
  font-weight: 500;
}

/* -------- MEDIA QUERIES -------- */
@media screen and (max-width: 1000px) {
  .prices-container {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 450px) {
  .prices-container, .prices-text-container {
    padding: 0 1rem;
  }
  .prices-table {
    font-size: 0.8rem;
  }
}
