.other-services-ul-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  padding-left: 0;
}

.other-services-ul-title{
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  color: #001F3F;
  text-align: center;
  margin: 2rem auto;
  padding: 0 2rem;
}

.other-services-ul-grid li {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #001F3F;
  color: white;
  font-size: 1.1rem;
  font-weight: 700;
  display: flex;
  height: 5rem;
  align-items: center;
  justify-content: center;
  border: 1px solid #001f3f;
  border-radius: 10px;
}

.other-services-ul-grid li:hover {
  background-color: #0074D9;
  color: white;
  border: 1px solid #001f3f;
  /* cursor: pointer; */
}



.other-services-intro-text {
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #212529;
  text-align: center;
}

.other-services-ul-text {
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  margin-bottom: 2rem;
}

.other-services-CTA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* width: 70%; */
  margin: 3rem auto;
  border: 3px solid #212529;
  background-color: #e6f1fb;
  color: #001F3F;
  border-radius: 10px;
  padding: 3rem;
}

@media screen and (max-width: 600px) {
  .other-services-container {
    padding: 0 1rem;
  }
  .other-services-intro-text {
    font-size: 1rem;
  }

  .other-services-ul-grid{
    grid-template-columns: repeat(2, 1fr);
  }

}

@media screen and (max-width: 500px){
  .other-services-CTA{
    padding: 2rem 1rem;
  }
}

@media screen and (max-width: 400px) {
  .other-services-ul-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .other-services-CTA {
    padding: 1rem;
  }
  .other-services-ul-title {
    font-size: 1.5rem;
  }
  .other-services-ul-text {
    font-size: 1rem;
  }
  .other-services-ul-grid li {
    font-size: 1rem;
  }
}
