.navbar-container {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: white;
  padding: 1.1rem 1rem;
  z-index: 9999;
}

.bg-myBlue {
  background-color: #001f3f;
}

.navbar-brand {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: #E04733;
  font-weight: 800;
}

.navbar-brand:hover {
  color: #E04733;
}

.navbar .navbar-toggler {
  border: none;
  outline: none;
  color: white !important;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9px;
}

.navbar-toggle .open .navbar-toggler:active .navbar-toggler:focus,
.navbar-toggler:active {
  outline: none;
  box-shadow: none;
}

.navbar .navbar-toggler .collapsed {
  width: 2.5rem;
  height: 2.5rem;
}

.navbar-link .nav-link {
  justify-content: center;
}

.navbar-link,
.navbar-dropdown-text {
  color: white;
  margin: auto 0;
  text-align: center;
}

.navbar-link:hover,
.navbar-link .nav-link:hover,
.navbar-dropdown-text:hover {
  color: #E04733;
}

.dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-toggle {
  color: white !important;
}

.dropdown-toggle:hover {
  color: #E04733 !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  margin-top: 1.1rem;
  border-radius: 0;
  background-color: white;
  padding: 0;
  border: none;
  overflow-x: hidden;
}

.dropdown-item {
  color: #001f3f;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.dropdown-item:first-of-type {
  padding-top: 1rem;
}

.dropdown-item:last-of-type {
  padding-bottom: 1rem;
}

.dropdown-item:hover {
  background-color: #001f3f;
  color: white;
  padding: 1rem;
}

.navbar-call-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 10rem;
  height: 3rem;
  background-color: #E04733;
  border-radius: 45px;
  color: white;
  padding-right: 0 !important;
  padding-left: 1rem !important;
}

.navbar-call-btn a {
  width: 10rem;
}

.navbar-call-btn span {
  width: 5rem;
}

.navbar-call-btn:hover {
  color: #001f3f;
}

/* --------- MEDIA QUERIES --------- */
@media screen and (max-width: 991px) {
  .navbar-link .nav-link {
    text-align: flex-start;
  }

  .dropdown {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    justify-content: center;
    padding: 0.5rem 0;
  }

  .nav-item .show .dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: 0;
    width: 105%;
    text-align: center;
    background-color: #e6f1fb;
  }

  .dropdown-item {
    color: #001f3f;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
  }

  .dropdown-item:hover {
    background-color: white;
    color: #001f3f;
    padding: 1rem;
  }

  .navbar-call-btn {
    margin: 0 auto;
    padding: 0 0 0 1.5rem;
    width: 12rem;
  }
}
