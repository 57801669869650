.contact-us-info-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 5rem 0;
  column-gap: 5.2rem;
  row-gap: 8rem;
  text-align: center;
}

.contact-us-info-box-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.contact-us-info-box-text-item img {
  width: 2rem;
  height: 2rem;
  margin-bottom: 1rem;
}

.contact-us-info-box-text-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.contact-us-info-box-map {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20rem;
}

.contact-us-info-box-map-img img {
  width: 100%;
  height: 20rem;
  object-fit: cover;
}

/* ----------- CONTACT US - STEPS ----------- */
/* ----- IMAGE BOX ----- */

.contact-us-step-img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.contact-us-step-img-box img {
  width: auto;
  height: 20rem;
}

@keyframes updown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate {
  animation: updown 2s ease-in-out infinite;
}

/* ----- TEXT BOX ----- */
.contact-us-step-text-box {
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
}

.contact-us-step-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.contact-us-step-description {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.contact-us-step-button {
  width: 20rem;
  margin-bottom: 1rem;
}

/* ----------- CONTACT US - FORM ----------- */

.contact-us-step-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* background-color: #e6f1fb;   */
}

.contact-us-step-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 10%;
  width: 60%;
  background-color: #e6f1fb;
  border-radius: 1rem;
  border: 2px solid #334c65;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.contact-us-step-form-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
}

.contact-us-step-form-description {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
  text-align: center;
  line-height: 1.2;
}

.contact-form-input {
  width: 100%;
  height: 2.5rem;
  margin-bottom: 1rem;
  border-radius: 4.5px;
  border: 1px solid #334c65;
}

/* .contact-form-file-btn {
  width: 100%;
  height: 2rem;
  margin-bottom: 1rem;
} */

.contact-form-textarea {
  width: 100%;
  height: 10rem;
  margin-bottom: 1rem;
  border-radius: 4.5px;
}

.contact-form-checkbox-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
}

.contact-form-checkbox-text {
  font-size: 0.8rem;
  font-weight: 400;
  margin-left: 0.5rem;
  text-align: left;
  line-height: 1.2;
}

.contact-form-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

form button {
  width: 100%;
  height: 2.5rem;
  margin-bottom: 1rem;
  border-radius: 4.5px;
  border: 1px solid #334c65;
  background-color: #0074d9;
  color: white;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0 1rem !important;
}

.contact-form-close-btn {
  border: 2px solid #9c9c9c;
  width: 40%;
  background-color: transparent;
  color: #9c9c9c;
  font-size: 1rem;
  font-weight: 700;
  padding: 0 1rem !important;
}

.contact-form-close-btn:hover {
  border: 2px solid #5a5a5a;
  background-color: transparent;
  color: #5a5a5a;
}

/* ------------ MEDIA QUERIES ------------ */

@media screen and (max-width: 1300px) {
  /* ----------- CONTACT US - INFO ----------- */
  .contact-us-info-grid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
    row-gap: 3rem;
  }

  .contact-us-info-box-map {
    grid-row: 1;
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .contact-us-info-box-openhours {
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

@media screen and (max-width: 844px) {
  /* ----------- CONTACT US - INFO ----------- */
  .contact-us-info-grid {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 3rem;
    row-gap: 3rem;
  }

  .contact-us-info-box-map {
    grid-row: 3;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .contact-us-info-box-openhours {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 2;
  }
}

@media screen and (max-width: 649px) {
  /* ----------- CONTACT US - FORM ----------- */
  .contact-form-button-wrapper {
    flex-direction: column;
    row-gap: 0rem;
  }

  .contact-us-step-form-wrapper{
    width: 90%;
  }

  .contact-form-close-btn {
    width: 100%;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 448px) {
  .contact-us-container {
    padding: 0 1rem;
  }

  /* ----- STEPS - IMAGE----- */

  .contact-us-step-img-box img {
    width: auto;
    height: 20rem;
  }

  /* ----- STEPS - TEXT----- */

  .contact-us-step-description {
    font-size: 1rem;
    line-height: 1.5;
  }

  .contact-us-info-box-map {
    width: auto;
    height: 15rem;
  }

  /* ----- INFO - IMAGE----- */
  .contact-us-info-box-map-img img {
    width: auto;
    height: 15rem;
  }
}

@media screen and (max-width: 378px) {
  .contact-us-info-box-map-img img {
    width: auto;
    height: 12rem;
  }
}

@media screen and (max-width: 300px) {
  .contact-us-info-box-map-img img {
    width: auto;
    height: 10rem;
  }
}
