/* --------------------- HOME - SERVICES --------------------- */
/* ------------ HOME - SERVICES - TITLE ------------ */
.home-services-title {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3rem;
}

/* ------------ HOME - SERVICES - INTRO ------------ */
.home-services-intro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.home-services-intro-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0rem;
  text-align: center;
  margin-bottom: 1rem;
  align-items: center;
}

.home-services-intro-text-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-right: 2px solid #e6f1fb;
  padding-right: 3rem;
}

.home-services-intro-text {
  font-size: 1.2rem;
  line-height: 1.5;
  width: 100%;
  text-align: center;
}

.home-services-intro-img-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-left: 3rem;
  /* margin: auto 0; */
}

.home-services-intro-img {
  width: 20rem;
  height: 15rem;
  object-fit: cover;
  object-position: center bottom;
}

.home-services-intro-img-text {
  font-size: 0.8rem;
  line-height: 1.5;
  width: 50%;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.home-services-intro-cta-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.home-services-intro-text:last-of-type {
  margin-top: 1rem;
  align-self: center;
  width: 100%;
  text-align: center;
}

/* ------------ HOME - SERVICES - LISTING ------------ */
.home-services-grid a {
  text-decoration: none;
  color: #333;
}

.home-services-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 3rem;
}

/* ---- HOME - SERVICES - LISTING - ITEM ---- */
/* -- HOME - SERVICES - LISTING - ITEM - BOX -- */

.home-services-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.home-services-box:hover {
  cursor: pointer;
  transform: scale(1.05);
}

/* - HOME - SERVICES - LISTING - ITEM - BOX - IMG - */

/* .home-services-box-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  background-color: #e6f1fb;
  border-radius: 50%;
} */

.home-services-box img {
  width: 4rem;
  height: 4rem;
}

/* - HOME - SERVICES - LISTING - ITEM - BOX - TEXT - */
.home-services-box-body-title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 1rem;
}

.home-services-box:hover .home-services-box-body-title {
  color: #E04733;
  border-bottom: 1px solid #E04733;
}

.home-services-box-body-text {
  font-size: 1.2rem;
  line-height: 1.5;
}

/* ------------ MEDIA QUERIES ------------ */
@media screen and (max-width: 1000px) {
  /* ------------ HOME - SERVICES - LISTING ------------ */
  .home-services-grid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
  }
}

@media screen and (max-width: 850px) {
  .home-services-intro-wrapper{
  grid-template-columns: 1fr;
  }

  .home-services-intro-text-wrapper{
    border-right: none;
    border-bottom: 2px solid #e6f1fb;
    padding-right: 0;
  }

  .home-services-intro-img-wrapper{
    padding-left: 0;
    padding-top: 2rem;
  }
}

@media screen and (max-width: 600px) {
  /* ------------ HOME - SERVICES - INTRO ------------ */
  .home-services-intro-wrapper {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .home-services-intro-text {
    width: 100%;
    text-align: center;
  }

  .home-services-intro-text:last-of-type {
    margin-top: 0rem;
    align-self: center;
    width: 100%;
    text-align: center;
  }

  /* ------------ HOME - SERVICES - LISTING ------------ */
  .home-services-grid {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 3rem;
  }

  /* ---- HOME - SERVICES - LISTING - ITEM ---- */
  /* -- HOME - SERVICES - LISTING - ITEM - BOX -- */
  .home-services-box {
    margin-bottom: 3rem;
  }
  /* - HOME - SERVICES - LISTING - ITEM - BOX - TEXT - */
  .home-services-box-body-title {
    margin: 2rem 0 1rem 0;
    height: auto;
  }
}

@media screen and (max-width: 400px) {
  .home-services-container {
    padding: 0 2rem;
  }
}
