/* /// COLORS ///
main color: #001F3F (Navy blue)
secondary color: #E04733 (Orange)
light color: #e6f1fb (Light blue)
shades:
-lighter-
10%: #1a3552
20%: #334c65
30%: #4d6279
40%: #66798c
50%: #808f9f
60%: #99a5b2
70%: #b3bcc5

-darker-
10%: #001c39
20%: #001932
30%: #00162c
40%: #001326
50%: #001020
60%: #000c19
70%: #000913

/*************************************/
/* GENERAL REUSABLE CLASSES */
/*************************************/

.container {
  /* 1140px */
  max-width: 120rem;
  padding: 0 3.2rem;
  margin: 0 auto 3rem auto;
}

/* ------------ GENERAL MARGINS ------------ */
.general-margin-top {
  margin-top: 3.2rem;
}

.margin-top-3 {
  margin-top: 3rem;
}

.margin-top-5 {
  margin-top: 5rem;
}

.navbar-margin-top {
  margin-top: 5.3rem;
}

/* ------------ GENERAL GRID AND FLEX LAYOUTS ------------ */

.grid {
  display: grid;
  column-gap: 5.2rem;
  row-gap: 8rem;
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}
.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}
.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

.grid--5-cols {
  grid-template-columns: repeat(5, 1fr);
}

/* .grid--2-rows{
  grid-template-rows: 2fr 0.5fr;
} */

.grid--center-v {
  align-items: center;
}

.flex-right-aligned {
  justify-content: flex-end;
}

/* ------------ GENERAL TEXT LAYOUTS ------------ */

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

/* ------------ GENERAL HEADING LAYOUTS ------------ */

.heading-primary {
  font-size: 3.2rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-bottom: 2.8rem;
}

.heading-secondary {
  font-size: 2.4rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-bottom: 2.8rem;
}

.heading-tertiary {
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-bottom: 2.8rem;
}

/* ------------ GENERAL BUTTON LAYOUTS ------------ */

.regular-button {
  background-color: #001f3f;
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 1rem;
  text-decoration: none;
}

.regular-button:hover {
  background-color: #E04733;
  color: white;
}

.btn:link,
.btn:visited,
.show-form-btn {
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 40px;
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btn-white {
  background-color: #fff;
  color: #777;
}

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.btn-white::after {
  background-color: #fff;
}

.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn-animated {
  animation: moveInBottom 5s ease-out;
  animation-fill-mode: backwards;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
