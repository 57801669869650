.home-testimonials-container {
  background-color: #e6f1fb;
  padding: 0;
}

.home-testimonials-title {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.home-testimonials-wrapper {
  display: grid;
  grid-template-columns: 55fr 45fr;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
}

.home-testimonials-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 3.2rem;
  width: 100%;
}

.home-testimonials-text-box {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 100%;
}

.home-testimonials-text-box-people{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 100%;
}

.home-testimonials-subtitle {
  font-size: 1.2rem;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
  padding-left: 1rem;
  margin-bottom: 1rem;
}
.home-testimonials-text-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
}

.home-testimonials-text-card-content {
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
  margin-bottom: 1rem;
  font-style: italic;
  line-height: 1.5;
}

.home-testimonials-text-card-name {
  width: 100%;
  font-size: 0.8rem;
  font-weight: 500;
  color: #555;
  text-align: left;
}

.home-testimonials-text-box-referencies-cta{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 100%;
}

.home-testimonials-text-box-referencies-cta-text{
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.home-testimonials-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 1rem;
}

.home-testimonials-gallery-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}

.home-testimonials-gallery-item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.home-testimonials-gallery-item img:hover {
  transform: scale(1.1);
}

/* ------------ MEDIA QUERIES ------------ */

@media screen and (max-width: 1350px) {
  .home-testimonials-gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 950px) {
  .home-testimonials-wrapper {
    grid-template-columns: 1fr;
  }

  .home-testimonials-text-wrapper {
    padding: 3rem 1.2rem;
  }

  .home-testimonials-gallery{
    grid-template-columns: repeat(6, 1fr);
  }
}

@media only screen and (max-width: 750px) {
  .home-testimonials-gallery{
    grid-template-columns: repeat(4, 1fr);
  }
}

/* @media only screen and (max-width: 600px) {
  .home-testimonials-gallery{
    grid-template-columns: repeat(3, 1fr);
  }
} */

@media only screen and (max-width: 550px){
  .home-testimonials-text-wrapper{
    padding: 3rem 1.2rem;
  }
  .home-testimonials-text-box{
    grid-template-columns: 1fr;
  }
}

/* @media only screen and (max-width: 450px) {
  .home-testimonials-gallery{
    grid-template-columns: repeat(2, 1fr);
  }
} */


