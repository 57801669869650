.jump-start-intro-text{
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #212529;
  text-align: center
}

/* ------ MEDIA QUERIES ------ */
@media screen and (max-width: 600px){
  .jump-start-container{
    padding: 0 1rem;
  }
  .jump-start-intro-text{
    font-size: 1rem;
  }
}