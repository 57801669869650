.sos-car-service-CTA{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* width: 70%; */
  margin: 3rem auto;
  border: 3px solid #212529;
  background-color: #e6f1fb;
  color: white;
  border-radius: 10px;
  padding: 3rem;
}

.sos-car-service-CTA-title{
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  color: #212529;
}

.sos-car-service-CTA-subtitle{
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.5;
  color: #212529;
  margin-bottom: 0;
}