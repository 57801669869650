.about-intro {
  margin-top: 5rem;
  margin-bottom: 5rem;
  text-align: center;
}

.about-intro-text {
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #212529;
}

/* GRID - WORKER */

.about-worker-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.about-worker-pic {
  display: flex;
  justify-content: center;
}
.about-worker-pic img {
  width: 15rem;
  height: 15rem; /* Make sure the height and width are the same */
  border-radius: 50%;
  object-fit: cover; 
}
.about-worker-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

.about-worker-text-title {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  color: #212529;
}

.about-worker-text-content {
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #212529;
}

.about-worker-text-content-quote {
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #212529;
  font-style: italic;
}

/* ---------- MEDIA QUERIES   */
@media screen and (max-width: 800px) {
  .about-intro-text {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 500px) {
  .about-container {
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .about-intro-text {
    font-size: 1.1rem;
  }

  .about-worker-text {
    width: 100%;
  }
}
