.hero-container {
  max-height: 80vh;
  overflow: hidden;
}

.hero-container .carousel-item {
  max-height: 80vh;
  overflow: hidden;
}

.hero-container .carousel-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: background 0.5s ease-in-out;
  z-index: 2;
}

.hero-img {
  /* height: 100%; */
  width: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}

@keyframes slideIn {
  0% {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

.hero-img-text-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  animation: slideIn 0.5s ease-in-out;
  z-index: 3;
}

.hero-img-text-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}

.hero-img-title {
  font-size: 4.8rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
}

.hero-img-description {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  padding: 0 3rem;
}

.hero-img-button {
  background-color: #E04733 !important;
  color: white !important;
}

.hero-img-button:hover {
  background-color: #E04733 !important;
  color: #001f3f !important;
}

/* ------- MEDIA QUERIES ------- */
@media screen and (min-width: 1550px) {
  .hero-img {
    top: -20rem;
  }

  #secondHomePic {
    top: -10rem;
  }

  #lastHomePic {
    top: 0rem;
  }
}

@media screen and (min-width: 1249px) and (max-width: 1549px) {
  .hero-img {
    top: calc(-15vh - 1vw);
  }
}

@media screen and (min-width: 1149px) and (max-width: 1248px) {
  .hero-img {
    top: calc(-5vh - 1vw);
  }
}

@media screen and (min-width: 720px) and (max-width: 1050px) {
  .hero-img-text-box {
    top: 50% !important;
  }
}
@media screen and (min-width: 600px) and (max-width: 1050px) {
  .hero-img-text-box {
    top: 40%;
  }

  .hero-img-title {
    font-size: 3rem;
    color: #fff;
    text-align: center;
  }

  .hero-img-description {
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
    padding: 0 1rem;
  }
}

@media screen and (max-width: 599px) {
  .hero-container {
    height: 75vh;
    overflow: hidden;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: none;
  }

  .hero-container .carousel-item {
    height: 75vh;
    overflow: hidden;
  }

  .hero-img {
    height: 100%;
    width: auto;
    max-width: 100%;
  }

  .hero-img-text-box {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 75%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    animation: slideIn 0.5s ease-in-out;
    z-index: 3;
  }

  .hero-img-title {
    font-size: 3rem;
    color: #fff;
    text-align: center;
  }

  .hero-img-description {
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
    padding: 0 1rem;
  }
}

@media screen and (max-width: 450px) {
  .hero-img-title {
    font-size: 2.5rem;
    color: #fff;
    text-align: center;
  }

  .hero-img-description {
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
    padding: 0 1rem;
  }
}
