.left-button, .right-button, .close-button {
  background-color: #e6f1fb;
  border: 1px solid #000;
  border-radius: 0.5rem;
  color: #001f3f;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 700;
  padding: 0.6rem 1.2rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.left-button {
margin-right: 1rem;
}

.right-button {
margin-left: 1rem;
}

.close-button {
  position: absolute;
  top: 3rem;
  right: 0;
  margin: 1rem;
}

/* ------- MEDIA QUERIES ------- */
@media (max-width: 399px) {
  .left-button, .right-button {
    /* Change the position of the buttons to below the image */
    position: static;
    display: block;
    margin: auto;
  }
}